export const adminRoot = "/";

export const currentUserApi =
  localStorage.getItem("user") != null
    ? JSON.parse(localStorage.getItem("user"))
    : null;

export const BASE_URL = process.env.DEV
  ? "https://wiestcars.de"
  : location.origin;

export const defaultAvatar = "/images/administrator.jpeg";
export const systemLogo = "/images/logo.png";
export const defaultImage = "/images/no-image.png";
export const CookieName = "cto_bidid_ass__";

export const currentUser = {
  id: 1,
  avatar: "/images/administrator.jpeg",
  title: "Admin",
};

export const isAuthGuardActive = true;
