/* eslint-disable eqeqeq */
import { firebaseDB } from "@/services/Firebase";
import { ReservationStatus, SeatStatus, SeatingType } from "@/utils/enum";
import {
  cloneCopy,
  dateTimeFormat,
  getStorage,
  orderBy,
  saveStorage,
  sumByCol,
} from "@/utils/helper";
import SeatAlreadyOccupiedError from "@/utils/seatAlreadyOccupiedError";
import {
  collection,
  query,
  getDocs,
  doc,
  onSnapshot,
  setDoc,
  runTransaction,
  Timestamp,
  where,
} from "firebase/firestore";

const state = {
  show: {},
  shows: [],
  seats: [],
  selectedShow: {
    location: "",
    show: null,
  },
  fetchingData: false,
  fetchingSeats: false,
  transactionProgress: false,
  dataLoaded: false,
  seatSnapshot: null,
};

const getters = {
  seats: (state) => state.show.seats,
  standingAvailable: (state) => (state.show.standing ? true : false),
  mapSeat: () => (seat) => {
    return {
      id: seat.id,
      label: seat.label,
      type: seat.type,
      category: seat.category,
      price: seat.price,
    };
  },
  mappedReservation: (state, getters, rootState) => (reservation) => {
    reservation.price = sumByCol(reservation.seats, "price");
    reservation.paymentCurrency = rootState.show.show.paymentCurrency;
    const guests = [];

    for (let index = 0; index < reservation.seats.length - 1; index++) {
      guests.push(reservation.name);
    }

    reservation.guests = guests;
    reservation.tickets = reservation.seats.length;
    reservation.event.date = Timestamp.fromDate(
      new Date(state.show.start.toDate())
    );

    reservation.serviceCharge = 0;
    if (state.show.serviceCharge) {
      reservation.serviceCharge =
        state.show.serviceCharge * reservation.tickets;
    }

    reservation.price += reservation.serviceCharge;

    delete reservation.id;
    return reservation;
  },
  seatCategories: (state, getters, rootState) => {
    const categories = state.seats
      .filter((a) => a.category)
      .map((a) => {
        return {
          name: a.category,
          price: a.price,
          color: a.color,
        };
      });

    const uniqueCategories = orderBy(
      [...new Set(categories.map(JSON.stringify))].map(JSON.parse),
      ["price"],
      ["asc"]
    );

    return uniqueCategories.map((a) => {
      a.tickets = rootState.reservation.selectedSeats.filter(
        (s) => s.category === a.name
      ).length;
      a.total = a.tickets * a.price;
      return a;
    });
  },
  paymentTypes: (state) => {
    const paymentTypes = [];
    if (state.show.paymentTypes) {
      if (state.show.paymentTypes.sepa) paymentTypes.push("sepa_debit");
      if (state.show.paymentTypes.card) paymentTypes.push("card");
      if (state.show.paymentTypes.giropay) paymentTypes.push("giropay");
      if (state.show.paymentTypes.sofort) paymentTypes.push("sofort");
      if (state.show.paymentTypes.klarna) paymentTypes.push("klarna");
    }

    return paymentTypes;
  },
  firstShow: (state) => {
    return state.shows.length ? state.shows[0] : {};
  },
  locations: (state) => {
    const _locations = [];
    state.shows.map((a) => {
      if (_locations.findIndex((l) => l.name === a.cinema.name) === -1) {
        _locations.push(a.cinema);
      }
    });
    return orderBy(_locations, ["city"], ["asc"]);
  },

  showTimes: (state) => {
    if (!state.selectedShow.location) return [];
    const _dateTime = [];
    state.shows
      .filter((s) => s.cinema.name === state.selectedShow.location)
      .map((a) => {
        if (_dateTime.findIndex((l) => l.name === a.cinema.name) === -1) {
          _dateTime.push({
            ...a,
            ...{
              time: dateTimeFormat(a.start.toDate(), "MM-DD-yyyy HH:mm:ss"),
            },
          });
        }
      });
    return orderBy(_dateTime, ["time"], ["asc"]);
  },
};

const actions = {
  async fetchByEventId({ commit, state, dispatch }, eventId) {
    state.fetchingData = true;
    let q = query(
      collection(firebaseDB, "screenings"),
      where("eventId", "==", eventId),
      where("webAppActive", "==", true),
      where("start", ">=", Timestamp.now()),
      where("maintenance", "==", false)
    );
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then(async (snap) => {
          const v = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            showData.id = doc.id;
            v.push(showData);
          });

          await commit("setState", { key: "shows", value: v });
          dispatch("setDefaultShowSelection");
          state.fetchingData = false;
          state.dataLoaded = true;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async fetchShows({ commit, state }) {
    state.fetchingData = true;
    let q = query(collection(firebaseDB, "shows"));
    return new Promise((resolve, reject) => {
      getDocs(q)
        .then((snap) => {
          const v = [];
          snap.forEach((doc) => {
            const showData = doc.data();
            v.push(showData);
          });

          commit("setShows", v);
          state.fetchingData = false;
          state.dataLoaded = true;
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async findShow({ commit, state }, id) {
    state.fetchingData = true;
    console.log("Fetching shows...");
    return new Promise((resolve) => {
      onSnapshot(doc(firebaseDB, "screenings", id), (doc) => {
        commit("updateShow", { ...{ id: doc.id }, ...doc.data() });
        state.fetchingData = false;
        console.log("Show updated...");
        resolve(true);
      });
    });
  },

  async fetchShowSeats({ state, commit }) {
    state.fetchingSeats = true;
    const q = query(
      collection(firebaseDB, `screenings/${state.show.id}/seats`)
      // limit(100)
    );
    console.log("Fetching seats..");
    state.seatSnapshot = onSnapshot(q, (querySnapshot) => {
      const seats = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          seats.push(change.doc.data());
        }
        if (change.type === "modified") {
          commit("updateSeat", change.doc.data());
          console.log("Seat Modified...");
        }
      });
      if (!state.seats.length) {
        commit("setState", { key: "seats", value: seats });
        console.log("Seats fetched..");
      }
      state.fetchingSeats = false;
    });
  },

  async unsubscribeSeatSeatSnapshot({ state }) {
    if (state.seatSnapshot) {
      console.log("Seat listener stopped");
      state.seatSnapshot();
      state.seatSnapshot = null;
      state.seats = [];
    }
  },
  async setDefaultShowSelection({ getters, commit, state }) {
    if (getters.locations.length === 1) {
      await commit("setState", {
        key: "selectedShow",
        value: {
          location: getters.locations[0].name,
        },
      });
    }
    if (getters.showTimes.length === 1) {
      await commit("setState", {
        key: "selectedShow",
        value: {
          location: state.selectedShow.location,
          show: getters.showTimes[0],
        },
      });
    }
  },

  async resetShowSelection({ state }) {
    state.selectedShow = {
      show: "",
      location: "",
    };
  },

  toggleSeatSelection({ state }, seat) {
    return new Promise((resolve) => {
      const docRef = doc(
        firebaseDB,
        `screenings/${state.show.id}/seats`,
        seat.id
      );

      const data = {
        status: SeatStatus.PAID,
      };

      setDoc(docRef, data, { merge: true })
        .then(() => {
          console.log("Document has been updated successfully");
        })
        .catch((error) => {
          console.log(error);
        });

      state.selectedSeats.push(seat);
      resolve(true);
    });
  },

  async markSeatAs({ state, rootState, rootGetters }, payload) {
    const show = rootState.show.show;
    const user = rootState.auth.currentUser;

    const seatDocRef = doc(
      firebaseDB,
      `screenings/${state.show.id}/seats`,
      payload.seat_id
    );

    let reservation = null;
    let reservationDocRef = null;
    if (rootGetters["reservation/isExistReservation"]) {
      reservationDocRef = doc(
        firebaseDB,
        `screenings/${show.id}/reservations`,
        rootState.reservation.reservationID
      );
    }
    state.transactionProgress = true;
    return runTransaction(firebaseDB, async (transaction) => {
      const seatDoc = await transaction.get(seatDocRef);
      const seat = seatDoc.data();

      if (rootGetters["reservation/isExistReservation"]) {
        // const reservationDoc = await transaction.get(reservationDocRef);
        // reservation = reservationDoc.data();
        reservation = cloneCopy(rootState.reservation.reservation);
      }

      let seatStatus = seat.status;
      // ! Seat transaction
      if (payload.status === SeatStatus.PAID) {
        await transaction.update(seatDocRef, {
          status: SeatStatus.FREE,
        });
        seatStatus = SeatStatus.FREE;
      } else if (seat.status === SeatStatus.PAID) {
        state.transactionProgress = false;
        throw new SeatAlreadyOccupiedError();
      }

      if (payload.status !== SeatStatus.PAID) {
        await transaction.update(seatDocRef, {
          status: SeatStatus.PAID,
        });
        seatStatus = SeatStatus.PAID;
      }

      // ! Reservation transaction
      if (rootGetters["reservation/isExistReservation"]) {
        const seats = reservation.seats;
        const existSeat = seats.find((a) => a.id === payload.seat_id);
        if (existSeat) {
          reservation.seats = seats.filter((a) => a.id !== existSeat.id);
        } else {
          reservation.seats.push(rootGetters["show/mapSeat"](seat));
        }

        reservation = rootGetters["show/mappedReservation"](reservation);

        const updateData = cloneCopy(reservation);
        delete updateData.created;

        await transaction.update(reservationDocRef, updateData);
      } else {
        const reservationDoc = collection(
          firebaseDB,
          `screenings/${show.id}/reservations`
        );

        reservation = {
          state: ReservationStatus.NEW,
          created: Timestamp.now(),
          device: "WEB",
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          userId: user.uid,
          type: show.tickets ? SeatingType.STANDING : SeatingType.SEATING,
          event: {
            eventId: show.eventId,
            id: show.id,
            name: show.movie.title,
            location: {
              name: show.cinema.name,
              hall: show.cinema.hall,
              street: show.cinema.street,
              postal: show.cinema.postal,
              city: show.cinema.city,
            },
            posterUrlSquare: show.movie.posterUrlSquare,
          },
          seats: [],
        };
        reservationDocRef = doc(reservationDoc);
        reservation.seats.push(rootGetters["show/mapSeat"](seat));
        reservation = rootGetters["show/mappedReservation"](reservation);

        await transaction.set(reservationDocRef, reservation);
        saveStorage("RID", reservationDocRef.id);
        saveStorage("SID", show.id);
      }

      reservation.id = getStorage("RID");
      state.transactionProgress = false;
      return {
        reservation,
        seat: {
          ...seat,
          ...{ status: seatStatus, loading: false },
        },
      };
    });
  },

  // async markSeatAs({ state, rootState, rootGetters }, payload) {
  //   const seatDocRef = doc(
  //     firebaseDB,
  //     `screenings/${state.show.id}/seats`,
  //     payload.seat.id
  //   );
  //   return runTransaction(firebaseDB, async (transaction) => {
  //     const seatDoc = await transaction.get(seatDocRef);
  //     const seat = seatDoc.data();

  //     let seatStatus = payload.seat.status;
  //     if (payload.seat.status === SeatStatus.PAID) {
  //       seatStatus = SeatStatus.FREE;
  //       await transaction.update(seatDocRef, {
  //         status: SeatStatus.FREE,
  //       });
  //       console.log("STATUS V2 PAID:", payload.seat, seat);
  //     } else if (seat.status === SeatStatus.PAID) {
  //       console.log("Error ", payload.seat, seat);
  //       throw new Error();
  //     }

  //     if (payload.seat.status !== SeatStatus.PAID) {
  //       console.log("STATUS V2 FREE:", payload.seat, seat);
  //       seatStatus = SeatStatus.PAID;
  //       await transaction.update(seatDocRef, {
  //         status: SeatStatus.PAID,
  //       });
  //     }

  //     const selectedSeats = rootGetters["reservation/selectedSeats"];
  //     const reservationData = {
  //       status: "NEW",
  //       device: "WEB",
  //       email: "ajanthan@gmail.com",
  //       event: rootState.event.event,
  //       seats: selectedSeats,
  //     };

  //     const existSeat = selectedSeats.find((a) => a.id === payload.seat.id);
  //     // ! toggle seat in reservation document
  //     if (existSeat) {
  //       reservationData.seats = selectedSeats.filter(
  //         (a) => a.id !== existSeat.id
  //       );
  //     } else {
  //       reservationData.seats.push(getters.mapSeat(payload.seat));
  //     }

  //     // ! Reservation transaction
  //     if (
  //       rootState.reservation.reservation.status &&
  //       rootState.reservation.reservation.status === "NEW"
  //     ) {
  //       console.log("EXIST RESERVATION:", payload.seat, seat);
  //       // const updateReservationRef = doc(
  //       //   firebaseDB,
  //       //   `screenings/${rootState.show.show.id}/reservations`,
  //       //   rootState.reservation.reservationID
  //       // );
  //       // await transaction.update(updateReservationRef, reservationData);
  //     } else {
  //       console.log("NEW RESERVATION:", payload.seat, seat);
  //       const reservationRef = collection(
  //         firebaseDB,
  //         `screenings/${rootState.show.show.id}/reservations`
  //       );
  //       const reservationDoc = doc(reservationRef);
  //       await transaction.set(reservationDoc, reservationData);
  //       saveStorage("RID", reservationDoc.id);
  //     }
  //     reservationData.id = getStorage("RID");
  //     return {
  //       reservationData,
  //       seat: {
  //         ...payload.seat,
  //         ...{ status: seatStatus, loading: false },
  //       },
  //     };

  //     // reservationData.id = getStorage("RID");
  //     // dispatch("reservation/attachReservation", reservationData, {
  //     //   root: true,
  //     // });
  //     // commit("updateSeat", {
  //     //   ...payload.seat,
  //     //   ...{ status: seatStatus, loading: false },
  //     // });
  //   });
  // },

  assignState({ commit }, options) {
    return new Promise((resolve) => {
      commit("setState", options);
      resolve(true);
    });
  },

  addShow({ commit }, show) {
    commit("newShow", show);
  },

  attachShow({ commit }, show) {
    commit("updateShow", show);
  },

  attachSeat({ commit }, seat) {
    commit("updateSeat", seat);
  },

  assignShow({ commit }, show) {
    commit("setShow", show);
  },
};

const mutations = {
  setShows(state, shows) {
    state.shows = shows;
  },

  updateShow(state, show) {
    const index = state.shows.findIndex((a) => a.id === show.id);
    state.shows[index] = show;
    state.show = show;
  },

  updateSeat(state, seat) {
    const seatIndex = state.seats.findIndex((a) => a.id === seat.id);
    state.seats[seatIndex] = seat;
  },

  setShow(state, show) {
    state.show = show;
  },

  setState(state, options) {
    return new Promise((resolve) => {
      state[options.key] = options.value;
      resolve(true);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
