// import * as firebase from 'firebase/app'
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// import * as admin from 'firebase-admin'

// Agregar configuración firebase:
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API, // 'AIzaSyAoeLg5wsvbFBbtp-5LzSiy4rKbgnJ9iIY',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN, // 'fleet-manager-autohaus-wiest.firebaseapp.com',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT, // 'fleet-manager-autohaus-wiest',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE, // 'fleet-manager-autohaus-wiest.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_ID, // '441469217875',
  appId: process.env.VUE_APP_FIREBASE_APP_ID, // '1:441469217875:web:0b020add217258af02aaa6',
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID, // 'G-7J0VFZ5NCL'
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// const adminApp = admin.initializeApp({
//   credential: admin.credential.applicationDefault()
// })

const firebaseAuth = getAuth(firebaseApp);
const firebaseDB = getFirestore();
const storage = getStorage(firebaseApp);

export { firebaseAuth, firebaseDB, storage };
