<template>
  <div class="animate-pulse border border-gray-900 bg-gray-900">
    <div class="content mt-[250px] p-10">
      <div class="h-3 bg-gray-700 mb-4 w-2/6 rounded-xl"></div>
      <div class="h-8 bg-gray-700 mb-4 w-3/6 rounded-xl"></div>
      <div class="h-3 bg-gray-700 mb-4 w-2/6 rounded-xl"></div>
      <div class="mt-5"></div>
      <div class="flex">
        <div class="h-5 bg-gray-700 mb-4 w-1/6 rounded-xl mr-10"></div>
        <div class="h-5 bg-gray-700 mb-4 w-1/6 rounded-xl"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SkeletonN3EventCard",
};
</script>
