import { isMobile } from "mobile-device-detect";

export default function handleMobile({ next, to }) {
  const pageUrl = "/download-our-app";
  if (isMobile && to.fullPath !== pageUrl) {
    return next(pageUrl);
  } else if (!isMobile && to.fullPath === pageUrl) {
    return next("/");
  }
  return next();
}
