import store from "@/store";
import { saveStorage } from "@/utils/helper";
export default function auth({ next, to }) {
  const user = store.state.auth.currentUser;

  if (!user) {
    saveStorage("REDIRECT_TO", to.fullPath);
    return next(`/auth/login`);
  }

  if (user) {
    return next();
  }
}
